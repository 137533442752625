body {
  margin: 0;
  font-family: "Jura", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
  overflow-x: hidden;
  background-color: #f9f3ee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  height: 20%;
  outline: transparent;
  border: 0.7px;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ecb365;
}
